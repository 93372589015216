<template>
  <div>
    <el-dialog
        title="批量设置"
        width="35%"
        :visible.sync="dialogState"
        append-to-body
        center
        @close="close">
      <el-form label-width="80px" size="medium">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="目标店铺">
              <el-select v-model="form.targetShopId" clearable style="width: 100%" placeholder="请选择目标店铺" @change="shopChange" ref="shop">
                <el-option
                    v-for="item in shopArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="调货目的">
              <el-select v-model="form.purpose" clearable style="width: 100%" placeholder="请选择调货目的">
                <el-option
                    v-for="item in purposeArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="调货周期" placeholder="请选择调货周期">
              <el-date-picker
                  v-model="form.dateRange"
                  type="daterange"
                  @change="dateRangeChange"
                  range-separator="至"
                  style="width: 100%"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="调货描述">
              <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="form.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "batch-setup",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  created() {
    this.pageInit()
  },
  data() {
    return {
      dialogState: this.state,
      shopArray: [],
      purposeArray: [
        {name: "出件", value: "出件"},
        {name: "预约锁定款式", value: "预约锁定款式"},
        {name: "归还", value: "归还"},
        {name: "分店清货", value: "分店清货"},
        {name: "其他", value: "其他"}
      ],
      form: {
        targetShopId: null,
        targetShopName: null,
        purpose: null,
        dateRange: null,
        remark: null,
        dateStart: null,
        dateEnd: null
      }
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopArray = JSON.parse(response.data.data)
      })
    },
    close() {
      this.$emit("close")
    },
    submit() {
      this.$emit("batchSetup", this.form)
    },
    shopChange() {
      this.$nextTick(() => {
        this.form.targetShopName = this.$refs.shop.selectedLabel
      })
    },
    dateRangeChange(val) {
      this.form.dateStart = this.$dateUtils.dateToYMDString(val[0])
      this.form.dateEnd = this.$dateUtils.dateToYMDString(val[1])
    }
  }
}
</script>

<style scoped>

</style>