<template>
  <div>
    <el-dialog
        title="订单婚纱"
        width="40%"
        :visible.sync="dialogState"
        append-to-body
        center
        @close="close">
      <el-descriptions title="客户信息">
        <el-descriptions-item label="用户名">{{order.name}}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{order.phone}}</el-descriptions-item>
        <el-descriptions-item label="婚期">{{order.weddingDay}}</el-descriptions-item>
        <el-descriptions-item label="订单项目">{{order.orderName}}</el-descriptions-item>
        <el-descriptions-item label="订单总价">{{order.orderPrice}}</el-descriptions-item>
      </el-descriptions>
      <el-divider content-position="left">该订单下的所有婚纱</el-divider>
      <el-table
          border
          v-loading="loading"
          ref="table"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
          @selection-change="handleSelectionChange"
          :data="scheduleList">
        <el-table-column
            type="selection"
            align="center">
        </el-table-column>
        <el-table-column prop="orderNo" label="婚纱名" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.styleType}}-{{scope.row.styleName}}-{{scope.row.clothesSize}}-{{scope.row.clothesNo}}
          </template>
        </el-table-column>
        <el-table-column prop="scheduleDate" label="使用档期" align="center" show-overflow-tooltip width="200">
        </el-table-column>
        <el-table-column label="是否可用" align="center">
          <template slot-scope="scope">
            <span :style="{color: scope.row.scheduleState === '撞挡' ? '#f56c6c' : '#409eff'}">
              {{scope.row.scheduleState}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="scope">
          <el-button type="text" size="medium" @click="clothesScheduleOpen(scope.row)">档期</el-button>
          <el-button type="text" size="medium" @click="showStyleImage(scope.row)">图片</el-button>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
    <clothesScheduleDetails v-if="schedulePageState" :clothesId="clothesId"  :state="schedulePageState" @close="schedulePageState=false"/>
  </div>
</template>

<script>
import clothesScheduleDetails from "@/components/clothes/clothes-schedule-details"

export default {
  name: "order-clothes",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    clothesScheduleDetails,
  },
  created() {
    this.queryClothesScheduleList()
    this.queryOperationRule()
  },
  data() {
    return {
      loading: true,
      dialogState: this.state,
      scheduleList: [],
      clothesId: "",
      schedulePageState: false,
      selectedClothes: [],
      operationRule: null,
      tenantCrop: localStorage.getItem("tenantCrop")
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    queryOperationRule() {
      this.$axios({
        method: "get",
        url: "/operationRule/queryOperationRule",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        this.operationRule = response.data.data.rule
      })
    },
    queryClothesScheduleList() {
      this.loading = true
      this.$axios({
        method: "GET",
        url: "/schedule/clothesAndScheduleByOrderId",
        params: {
          orderId: this.order.id,
        },
      }).then(response => {
        this.loading = false
        this.scheduleList = response.data.data
      })
    },
    // 查看订单图片
    async showStyleImage(data) {
      const response = await this.$axios({
        method:"GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: data.styleId,
        }
      });
      this.styleImageList = response.data.data
      this.$viewerApi({
        images: this.styleImageList,
      })
    },
    clothesScheduleOpen(val) {
      this.clothesId = val.clothesId
      this.schedulePageState = true
    },
    handleSelectionChange(val) {
      this.selectedClothes =val
    },
    submit() {
      if (this.selectedClothes.length == 0) {
        this.$message.error("未选择婚纱无法提交")
        return
      }
      this.selectedClothes.forEach(s => {
        s.tenantCrop = this.tenantCrop
        s.orderId = this.order.id
        s.targetShopName = this.order.shopName
        s.targetShopId = this.order.shopId
        let schedules = s.scheduleDate.split(" - ")
        s.dateStart = this.$dateUtils.getAroundDateToYMD(schedules[0], this.operationRule)[0]
        s.dateEnd = this.$dateUtils.getAroundDateToYMD(schedules[1], this.operationRule)[1]
        s.dateRange = [s.dateStart, s.dateEnd]
      })
      this.$emit("selectClothes", this.selectedClothes)
    },
  }
}
</script>

<style scoped>

</style>