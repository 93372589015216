<template>
  <div>
    <el-dialog
        title="预约婚纱"
        width="40%"
        :visible.sync="dialogState"
        append-to-body
        center
        @close="close">
      <el-descriptions title="客户信息" :column="2">
        <el-descriptions-item label="用户名">{{appoint.name}}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{appoint.phone}}</el-descriptions-item>
        <el-descriptions-item label="预约店铺">{{appoint.appointShop}}</el-descriptions-item>
        <el-descriptions-item label="到店日期">{{appoint.appointDate}}</el-descriptions-item>
      </el-descriptions>
      <el-divider content-position="left">该预约下的所有预约婚纱</el-divider>
      <el-table
          border
          v-loading="loading"
          ref="table"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
          @selection-change="handleSelectionChange"
          :data="clothesList">
        <el-table-column
            type="selection"
            align="center">
        </el-table-column>
        <el-table-column prop="orderNo" label="婚纱名" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.styleType}}-{{scope.row.styleName}}-{{scope.row.clothesSize}}-{{scope.row.clothesNo}}
          </template>
        </el-table-column>
        <el-table-column prop="positionName" label="所在位置" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="是否撞挡" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span :style="{color: scope.row.scheduleState === 1 ? '#409eff' : '#f56c6c'}">
              {{ scope.row.scheduleState === 1 ? "可用" : "撞挡" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="scope">
          <el-button type="text" size="medium" @click="clothesScheduleOpen(scope.row)">档期</el-button>
          <el-button type="text" size="medium" @click="showStyleImage(scope.row)">图片</el-button>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
    <clothesScheduleDetails v-if="schedulePageState" :clothesId="clothesId"  :state="schedulePageState" @close="schedulePageState=false"/>
  </div>
</template>

<script>
import clothesScheduleDetails from "@/components/clothes/clothes-schedule-details"


export default {
  name: "appoint-clothes",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
    appoint: {
      type: Object,
      required: true,
    }
  },
  components: {
    clothesScheduleDetails
  },
  created() {
    this.queryAppointYarnList()
    this.queryOperationRule()
    console.log(this.appoint)
  },
  data() {
    return {
      dialogState: this.state,
      clothesList: [],
      loading: true,
      schedulePageState: false,
      clothesId: null,
      selectedClothes: [],
      operationRule: null,
      tenantCrop: localStorage.getItem("tenantCrop")
    }
  },
  methods: {
    queryOperationRule() {
      this.$axios({
        method: "get",
        url: "/operationRule/queryOperationRule",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        this.operationRule = response.data.data.rule
      })
    },
    close() {
      this.$emit("close")
    },
    queryAppointYarnList() {
      this.loading = true
      this.$axios({
        method: "GET",
        url: "/appointYarn/queryAppointYarnList",
        params: {
          appId: this.appoint.id,
        },
      }).then(response => {
        this.clothesList = response.data.data
        this.loading = false
      })
    },
    handleSelectionChange(val) {
      this.selectedClothes = val
      console.log(val)
      console.log(this.appoint)
    },
    clothesScheduleOpen(val) {
      this.clothesId = val.clothesId
      this.schedulePageState = true
    },
    async showStyleImage(data) {
      const response = await this.$axios({
        method:"GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: data.styleId,
        }
      });
      this.styleImageList = response.data.data
      this.$viewerApi({
        images: this.styleImageList,
      })
    },
    submit() {
      if (this.selectedClothes.length == 0) {
        this.$message.error("未选择婚纱无法提交")
        return
      }
      this.selectedClothes.forEach(s => {
        s.tenantCrop = this.tenantCrop
        s.appointId = this.appoint.id
        s.targetShopName = this.appoint.appointShop
        s.targetShopId = this.appoint.shopId
        s.dateRange = this.$dateUtils.getAroundDateToYMD(this.appoint.appointDate, this.operationRule)
        s.dateStart = s.dateRange[0]
        s.dateEnd = s.dateRange[1]
      })
      this.$emit("selectClothes", this.selectedClothes)
    },
  },
}
</script>

<style scoped>

</style>