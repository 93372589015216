<template>
  <div>
    <el-dialog
        title="订单列表"
        width="55%"
        top="10vh"
        :visible.sync="dialogState"
        :modal-append-to-body="false"
        @close="close"
        center>
      <fieldset style="width:97%">
        <legend>搜索信息</legend>
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input placeholder="客户姓名" size="medium" v-model="name" clearable></el-input>
          </el-col>
          <el-col :span="5">
            <el-input placeholder="订单号" size="medium" v-model="orderNo" clearable></el-input>
          </el-col>
          <el-col :span="5">
            <el-input placeholder="联系方式" size="medium" v-model="phone" clearable></el-input>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" size="medium" @click="search">搜索</el-button>
            <el-button type="warning" size="medium">重置</el-button>
          </el-col>
        </el-row>
      </fieldset>
      <el-row style="margin-top: 15px;padding-left: 10px">
        <el-table
            ref="table"
            :data="orderArray"
            border
            v-loading="loading"
            highlight-current-row
            element-loading-text="拼命加载中"
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}">
          <el-table-column prop="name" label="客户名" align="center" width="100" fixed show-overflow-tooltip></el-table-column>
          <el-table-column prop="orderNo" label="订单号" align="center" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="orderName" label="订单项目" align="center" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="phone" label="联系方式" align="center" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createDate" label="下单日期" align="center" width="120" sortable></el-table-column>
          <el-table-column prop="dress" label="礼服师" align="center" width="120"></el-table-column>
          <el-table-column prop="source" label="渠道来源" align="center" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="weddingDay" label="精确婚期" align="center" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="orderPrice" label="订单总价" align="center" width="120"></el-table-column>
          <el-table-column prop="spareMoney" label="收款金额" align="center" width="120"></el-table-column>
          <el-table-column prop="orderRemark" label="备注" align="center" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" width="120" fixed="right" label="操作" v-slot="scope">
            <el-button type="primary" size="mini" @click="orderClothesOpen(scope.row)">订单婚纱</el-button>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row style="margin-top: 3px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="10"
            layout="prev, pager, next,total, jumper, sizes"
            :total="total">
        </el-pagination>
      </el-row>
    </el-dialog>
    <order-clothes v-if="orderClothesState" :state="orderClothesState" :order="selectedOrder" @close="orderClothesClose" @selectClothes="selectClothes"></order-clothes>
  </div>
</template>

<script>
import orderClothes from "@/pages/clothesOperation/fill-operation/order-clothes"

export default {
  name: "order-list",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  components: {
    orderClothes
  },
  created() {
    this.orderList()
  },
  data() {
    return {
      loading: true,
      dialogState: this.state,
      orderArray: [],
      total: 0,
      page: 1,
      limit: 10,
      name: "",
      orderNo: null,
      phone: null,
      orderClothesState: false,
      selectedOrder: {},
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    orderList() {
      this.loading = true
      this.$axios({
        method: "get",
        url: "/order/orderList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          limit: this.limit,
          page: this.page,
          shopIds: localStorage.getItem("shops"),
          name: this.name,
          orderNo: this.orderNo,
          phone: this.phone,
        }
      }).then(response => {
        this.loading = false
        this.orderArray = response.data.data.list;
        this.total = response.data.data.total;
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.orderList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.orderList()
    },
    orderClothesOpen(val) {
      this.orderClothesState = true
      this.selectedOrder = val
    },
    search() {
      this.page = 1
      this.orderList()
    },
    orderClothesClose() {
      this.orderClothesState = false
    },
    selectClothes(val) {
      this.$emit("selectClothes", val)
      this.orderClothesClose()
    }
  }
}
</script>

<style scoped>

</style>