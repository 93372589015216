<template>
  <div>
    <el-dialog
        :title="'调货申请设置(' + clothesName +')'"
        width="40%"
        :visible.sync="dialogState"
        append-to-body
        center
        @close="close">
      <el-form label-width="80px" size="medium" :model="setupClothes" :rules="rules" ref="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="目标店铺" prop="targetShopId">
              <el-select v-model="setupClothes.targetShopId" clearable style="width: 100%" placeholder="请选择目标店铺" @change="shopChange" ref="shop">
                <el-option
                    v-for="item in shopArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="调货目的" prop="purpose">
              <el-select v-model="setupClothes.purpose" clearable style="width: 100%" placeholder="请选择调货目的">
                <el-option
                    v-for="item in purposeArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="调货周期" placeholder="请选择调货周期" prop="dateRange">
              <el-date-picker
                  v-model="setupClothes.dateRange"
                  type="daterange"
                  @change="dateRangeChange"
                  range-separator="至"
                  style="width: 100%"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="调货描述" prop="remark">
              <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="setupClothes.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "single-setup",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
    clothes: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.$selectUtils.shopIdsIsValid().then(response => {
      this.shopArray = JSON.parse(response.data.data)
    })
  },
  data() {
    return {
      setupClothes: {...this.clothes},
      dialogState: this.state,
      clothesName: this.clothes.styleType + "-" + this.clothes.styleName + "-" + this.clothes.clothesSize + "-" + this.clothes.clothesNo,
      shopArray: [],
      purposeArray: [
        {name: "出件", value: "出件"},
        {name: "预约锁定款式", value: "预约锁定款式"},
        {name: "归还", value: "归还"},
        {name: "分店清货", value: "分店清货"},
        {name: "其他", value: "其他"}
      ],
      rules: {
        targetShopId: [{required: true, message: "请选择目标店铺", trigger: "change"}],
        purpose: [{required: true, message: "请选择调货目的", trigger: "change"}],
        dateRange: [{required: true, message: "请选择调货周期", trigger: "change"}],
        remark: [{required: true, message: "请选择调货描述", trigger: "change"}],
      },
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("singleSetup", this.setupClothes)
        }
      })
    },
    shopChange() {
      this.$nextTick(() => {
        this.setupClothes.targetShopName = this.$refs.shop.selectedLabel
      })
    },
    dateRangeChange(val) {
      this.setupClothes.dateStart = this.$dateUtils.dateToYMDString(val[0])
      this.setupClothes.dateEnd = this.$dateUtils.dateToYMDString(val[1])
    }
  }
}
</script>

<style scoped>

</style>