<template>
  <div>
    <el-dialog
        title="预约列表"
        width="55%"
        top="10vh"
        :visible.sync="dialogState"
        :modal-append-to-body="false"
        @close="close"
        center>
      <fieldset style="width:97%">
        <legend>搜索信息</legend>
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input placeholder="客户姓名" size="medium" v-model="name" clearable></el-input>
          </el-col>
          <el-col :span="5">
            <el-input placeholder="联系方式" size="medium" v-model="phone" clearable></el-input>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" size="medium" @click="search">搜索</el-button>
            <el-button type="warning" size="medium">重置</el-button>
          </el-col>
        </el-row>
      </fieldset>
      <el-row style="margin-top: 15px;padding-left: 10px">
        <el-table
            ref="table"
            :data="appointList"
            border
            v-loading="loading"
            highlight-current-row
            element-loading-text="拼命加载中"
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}">
          <el-table-column prop="name" label="客户名" align="center" width="120" fixed show-overflow-tooltip></el-table-column>
          <el-table-column prop="phone" label="手机号" align="center" width="126"></el-table-column>
          <el-table-column prop="weChat" label="微信号" align="center" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="appointDate" label="到店日期" sortable align="center" width="120"></el-table-column>
          <el-table-column prop="appointTime" label="到店时间" align="center" width="120"></el-table-column>
          <el-table-column prop="appointShop" label="预约店铺" align="center" width="120"></el-table-column>
          <el-table-column prop="inviter" label="预约人" align="center" width="120"></el-table-column>
          <el-table-column prop="appStateName" label="预约状态" align="center" width="120"></el-table-column>
          <el-table-column prop="appointDress" label="礼服师" align="center" width="120"></el-table-column>
          <el-table-column prop="remark" label="预约备注" align="center" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column width="120" align="center" fixed="right" label="操作" v-slot="scope">
            <el-button type="primary" size="mini" @click="appointClothesOpen(scope.row)">预约婚纱</el-button>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row style="margin-top: 3px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="10"
            layout="prev, pager, next,total, jumper, sizes"
            :total="total">
        </el-pagination>
      </el-row>
    </el-dialog>
    <appoint-clothes v-if="appointClothesState" :state="appointClothesState" :appoint="selectedAppoint" @close="appointClothesClose" @selectClothes="selectClothes"></appoint-clothes>
  </div>
</template>

<script>
import appointClothes from "@/pages/clothesOperation/fill-operation/appoint-clothes"


export default {
  name: "appoint-list.vue",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  components: {
    appointClothes
  },
  created() {
    this.queryAppList()
  },
  data() {
    return {
      dialogState: this.state,
      appointList: [],
      name: "",
      phone: "",
      total: 0,
      page: 1,
      limit: 10,
      loading: false,
      appointClothesState: false,
      selectedAppoint: {},
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    search() {

    },
    queryAppList() {
      this.loading = true
      this.$axios({
        method: "get",
        url: "/appoint/queryAppointListHasYarn",
        params: {
          limit: this.limit,
          page: this.page,
          tenantCrop: localStorage.getItem("tenantCrop"),
          name: this.name,
          phone: this.phone,
        }
      }).then(response => {
        this.loading = false
        this.appointList = response.data.data.list
        this.total = response.data.data.total;
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryAppList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryAppList()
    },
    appointClothesOpen(val) {
      this.selectedAppoint = val
      this.appointClothesState = true
    },
    appointClothesClose() {
      this.appointClothesState = false
    },
    selectClothes(val) {
      this.$emit("selectClothes", val)
      this.appointClothesClose()
    }
  },
}
</script>

<style scoped>

</style>